import React, { useState, useRef, useEffect } from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import { ChevronUp16, Location16 } from "@carbon/icons-react"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"

const VariantCard = ({ item }) => {
  const [accessoriesIsOpenState, setAccessoriesIsOpenState] = useState(true)

  const refAccessoriesContainer = useRef()

  if (item.image === "undefined") {
    item.image = { filename: process.env.GATSBY_IMG_PLACEHOLDER }
  }

  useEffect(() => {
    setTimeout(() => {
      setAccessoriesIsOpenState(false)
    }, 500)
  }, [])

  return (
    <div
      className={`block h-full border focus:outline-none ${
        item?.active ? "border-primary" : "border-gray-100"
      }`}
    >
      <div className="border-b border-gray-100">
        <Image
          className="w-full h-auto"
          image={item.image}
          aspectRatio="1by1"
          fitIn="true"
          fillColor="ffffff"
          forceLoad={true}
        />
      </div>
      <div className="h-48"></div>

      <div className="relative">
        {item.accessories && (
          <button
            className="flex group w-full text-left bg-white border-t border-gray-100 btn whitespace-nowrap"
            onClick={() => {
              setAccessoriesIsOpenState(!accessoriesIsOpenState ? true : false)
            }}
          >
            <span>Lieferumfang</span>
            <ChevronUp16
              className={`inline-block h-6 ml-auto -mt-0.25 align-middle transition-all group-hover:-mt-0.5 group-hover:mb-0.5 duration-300 transform ${
                accessoriesIsOpenState ? "rotate-180" : ""
              }`}
            />
          </button>
        )}

        <div className="absolute left-0 right-0 z-50 min-w-full bg-white bottom-full">
          <div className="flex flex-col justify-between h-48 p-4 -mb-px border-b border-gray-100">
            <div>
              <span className="font-bold text-primary">{item.headline}</span>
              <Richtext className="mt-2 prose-sm" text={item.text} />
            </div>

            <div>
              <Button
                className="flex w-full mt-8 text-left"
                btnType="secondary"
                link={item.active ? "" : item.slug}
                disabled={item.active ? true : false}
              >
                {item.active ? "Aktuell gewählt" : "Variante auswählen"}
              </Button>
              {/* <Button
                className="flex w-full mt-4 text-left"
                btnType="primary"
                link="#nexmartRetailers"
              >
                Kaufen
                <Location16 className="inline-block h-6 ml-auto -mt-0.25 align-middle" />
              </Button> */}
            </div>
          </div>
          {item.accessories && (
            <div
              className="overflow-hidden transition-all duration-500 "
              ref={refAccessoriesContainer}
              style={{
                height: `${
                  accessoriesIsOpenState === true
                    ? `${refAccessoriesContainer?.current?.scrollHeight}px`
                    : "0px"
                }`,
              }}
            >
              <Richtext
                className="px-4 py-3 leading-6 prose-sm prose max-w-none"
                text={item.accessories}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default VariantCard
